/* Contact Section Styles */
.contact-section {
  padding: 60px 20px;
  background-color: #1e1e2d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.contact-content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

/* Centered Title */
.contact-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.title-main {
  color: white;
}

.title-highlight {
  color: #ff4747;
  font-weight: 600;
}

/* Contact Layout */
.contact-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

/* Left Side - Contact Information */
.contact-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px; /* Adds space between the items */
  text-align: left;
  align-items: left;
  height: 50vh; /* Center the content vertically */
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item i {
  font-size: 2rem;
  color: #ff4747;
  margin-right: 10px;
}

.contact-item a {
  color: white;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

/* Right Side - Contact Form */
.contact-form {
  flex: 1;
  text-align: left;
}

.form-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ff4747;
}

.form-subtitle {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  font-size: 1rem;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.9);
}

.form-group input, .form-group textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cc0000;
  border-radius: 8px;
  background-color: #1e1e2d;
  color: white;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.submit-button {
  background-color: #ff4747;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: #cc0000;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-layout {
      flex-direction: column;
  }

  .contact-info {
      margin-bottom: 30px;
      height: auto; /* Remove height restriction on smaller screens */
  }
}
