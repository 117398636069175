/* About Us Section */
.about-us-section {
  padding: 60px 20px;
  background-color: #1e1e2d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.about-us-content {
  max-width: 1200px;
  text-align: center;
  position: relative;
}

/* Main Title */
.about-us-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: white;
}

.title-main {
  color: white;
}

.title-highlight {
  color: #ff4747;
  font-weight: 600;
}

/* Subheading */
.about-us-subheading {
  font-size: 1.8rem;
  color: #ff4747; /* Red color to match your website’s design */
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* About Us Items - Cards */
.about-us-items {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.about-us-card {
  background-color: #2c2c3d;
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-us-card:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
}

.about-us-icon {
  font-size: 3rem;
  color: #ff4747;
  margin-bottom: 15px;
}

.about-us-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-us-card-description {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

/* About Us Text - Centered Content */
.about-us-text {
  margin-top: 40px;
  text-align: center; /* Center the text */
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.85);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .about-us-items {
      flex-direction: column;
      gap: 20px;
  }

  .about-us-card {
      width: 100%;
  }

  .about-us-title {
      font-size: 2.5rem;
  }

  .about-us-subheading {
      font-size: 1.5rem;
  }
}
